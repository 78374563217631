import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { capitalize, chain, filter, find, get, intersection, isEqual, map, orderBy, reduce } from 'lodash';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles'
import memo from 'memoize-one';
import moment from 'moment';

import { openModal } from '../state/modal';
import { fetchForms } from '../actions/forms';
import { fetchMigraines } from '../actions/migraines';

import { readUserEmr, editUserEmr } from '../state/emr';
import {
  clearEmrMessage, mergePatientData, handleSkip, unmatchPatient,
  editUser, readUserEhr, readInformedData
} from '../state/merge-data';
import { browseProviders } from '../state/clinic';
import { browseDocuments, browseSignatureRequests, addSignatureRequest } from '../state/esign';
import { browseProCampaigns, browseProSubmissions, createProCampaign, createProSubmission, deleteProCampaign, editProSubmission, editProCampaign } from '../state/pro-submissions';
import { browseSurgery, browsePainLocations, browseProTypes } from '../state/app-data';
import { browsePainHx } from '../state/pain-hx';
import { addSurgeryHx, editSurgeryHx, browseSurgeryHx } from '../state/surgery-hx';
import { createAppointment, readPatient, updateAppointment, updatePatient } from '../state/appointments';
import { addRecent } from '../state/recent';

import AppWrapper from '../wrappers/app-wrapper';
import Tabs from '../components/patient-view-tabs';
import Loading from '../components/loading';
import HeadacheOverviewTab from '../components/headache-overview-tab';
import PatientOverviewTab from '../components/patient-overview-tab';
import AppointmentsTab from '../components/appointments-overview-tab';
import ReleasesOverviewTab from '../components/releases-overview-tab';
import ProSubmissionsTab from '../components/pro-submissions-overview-tab';
import BodyOverviewTab from '../components/body-overview-tab';
import EmrOverviewTab from '../components/emr-overview-tab';
import ImagesOverviewTab from '../components/images-overview-tab';
import PatientViewHeader from '../components/patient-view-header';
import PatientHeaderProgressCircles from '../components/patient-header-progress-circles';
import PatientSummary from '../components/patient-summary';
import VitalsOverviewTab from '../components/vitals-overview-tab';
import { apiFetch } from '../lib/fetch';
import openForm from '../lib/open-form';
import { colors } from '../lib/styles';

const styles = {
  contentStyle: {
    margin: '15px',
    width: '100%',
    minWidth: '768px',
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
  },
  tab: {
    color: colors.highlightDarker,
    fontSize: '.85em',
    fonWeight: 100,
    textTransform: 'capitalize',
  },
  tabTemplate: {},
  iconTab: {
    display: 'flex',
    alignItems: 'center',
  },
  closeBtn: {
    border: `1px solid ${colors.highlightDark}`,
    boxShadow: 'none',
    margin: '5px',
  },
};

function getFirstTab(features) {
  return intersection([
    'VITALS_OVERVIEW',
    'PRO_SUBMISSIONS',
    'PATIENT_OVERVIEW',
    'VIDEO_CALLS',
    'EMR_INTEGRATION',
    'ESIGN_RELEASES',
    'HEADACHE_OVERVIEW',
    'PAIN_OVERVIEW',
  ], features)[0];
}

class AppointmentView extends Component {
  state = {
    blacklistedPhone: false,
    landlinePhone: false,
    tab: 'PATIENT_OVERVIEW',
    verifiedPatientNumber: false,
    contactStatus: '',
  }
  componentWillMount() {
    const { features, userId } = this.props;
    const startingTab = getFirstTab(features);
    this.props.browseSurgery();
    this.props.browseProTypes();

    if (userId && startingTab) {
      this.props.browseProCampaigns(userId);
      this.props.readUserEmr(userId);
      this.props.fetchForms(userId);
      this.props.readPatient(userId);
      this.props.browseSignatureRequests({ user_id: userId });
      this.props.fetchMigraines(userId);
      this.props.browsePainHx(userId);
      this.props.browseProSubmissions(userId);
      this.props.browseSurgeryHx(userId);
      this.props.browseDocuments();

      this.setState({
        tab: startingTab
      });
    } else {
      this.props.router.push('/app');
    }
  }
  
  componentDidUpdate(prevProps) {
    const { appointments, userId } = this.props;
    const appointment = find(appointments.data, { user_id: userId }) || {};
    const prevAppointment = find(prevProps.appointments.data, { user_id: userId }) || {};

    if (!isEqual(appointment, prevAppointment)) {
      const contactBlacklistOptions = {
        method: 'GET',
        query: {
          phone: appointment.cell_phone,
          user_id: this.props.userId,
        }
      }
    
      appointment.cell_phone ? apiFetch(`/contact_blacklist`, contactBlacklistOptions)
        .then(async res => {
          let verified = res[0];
          if (!res[0]) verified = await apiFetch(`/contact_blacklist/verify`, contactBlacklistOptions)
          this.setState({ verifiedPatientNumber: true });
          if(verified.type !== 'LANDLINE' && !verified.contact_enabled) {
            return this.setState({ contactStatus: 'blacklisted'});
          }
          if (verified.type === 'LANDLINE') {
            return this.setState({ contactStatus: 'landline' });
          }
          if (verified.type === 'MOBILE' && verified.contact_enabled) {
            return this.setState({ contactStatus: 'enabled' });
          }
        })
        .catch(() => this.setState({ contactStatus: 'error' }))
        : this.setState({ contactStatus: 'no_phone' });
    }
  }
  
  componentWillReceiveProps(nextProps) {
    const { userId } = this.props;
    const newUserId = nextProps.userId;
    if (userId !== newUserId) {
      this.props.browseProCampaigns(newUserId);
      this.props.readUserEmr(newUserId);
      this.props.fetchForms(newUserId);
      this.props.readPatient(newUserId);
      this.props.browseSignatureRequests({ user_id: newUserId });
      this.props.fetchMigraines(newUserId);
      this.props.browsePainHx(newUserId);
      this.props.browseProSubmissions(newUserId);
      this.props.browseSurgeryHx(newUserId);
    }
  }
  componentWillUnmount() {
    this.props.mergePatientData(false);
  }
  handleBrowseProSubmissions = () => {
    const { browseProSubmissions, userId } = this.props;
    browseProSubmissions(userId);
  }
  handleChangeTab = (evt, tab) => {
    this.setState({ tab });
  }
  handleOpenForm = (formName) => {
    openForm(this.props.userId, formName);
  }
  handleOpenAllForms = () => {
    openForm(this.props.userId, 'all');
  }
  handleSendSignatureRequest = (templateId) => {
    this.props.addSignatureRequest(this.props.userId, templateId);
  }
  handleOpenCheckInModal = () => {
    this.props.openModal('checkInModalOpen');
  }
  memoBuildSurgeries = memo((surgeryData, surgeryTypes) => {
    return map(surgeryData, (s ={}) => {

      const foundSurgery = surgeryTypes.find((surgeryType) => surgeryType.surgery_id === s.surgery_id) || {};
      return {
        id: s.id,
        name: s.name || foundSurgery.name,
        date: moment.utc(s.year).format('MM/DD/YYYY'),
        year: s.year, // for sorting
      }
    })
  })
  memoMarkProsViewed = memo((tab, totalPros, userId) => {
    if(tab !== 'PRO_SUBMISSIONS') return;
    if(totalPros < 1) return;

    const unreadPros = filter(this.props.proSubmissions, p => !p.clinic_viewed_result && p.pro_score_data);
    if(!unreadPros.length) return;
    const promises = [];

    unreadPros.forEach((pro) => {
      promises.push(this.props.editProSubmission({ id: pro.id, user_id: pro.user_id, clinic_viewed_result: true }));
    });

    Promise.all(promises)
    .then(() => this.props.readPatient(userId))
    .then(() => this.props.browseProSubmissions(userId));
  })
  render() {
    const { handleOpenAllForms, handleOpenForm } = this;
    const { tab } = this.state;
    const { userId, forms, appointments, clinicId, createAppointment, emr, emrInstances, mergeData, updateAppointment, painHx, esign, router, proSubmissions, documentTemplates, contact_preference } = this.props;
    const surgeries = this.memoBuildSurgeries(this.props.surgeryData, this.props.surgeryTypes);
    this.memoMarkProsViewed(tab, proSubmissions.length, userId);

    if (emr.isFetching || this.props.migraines.isFetching || appointments.isFetching || painHx.browsePending || forms.isFetching || esign.isFetching) {
      return (
        <AppWrapper iconSidebar={true} router={this.props.router}>
          <div className={this.props.classes.contentStyle}>
            <Loading />
          </div>
        </AppWrapper>
      );
    }

    if (this.props.appointments.error) {
      return (
        <AppWrapper router={this.props.router}>
          <div className={this.props.classes.contentStyle}>
            <p style={{ marginTop: '100px' }}>{this.props.appointments.error.message}</p>
            <Button
              onClick={() => { this.props.router.push('/app'); }}
              className={this.props.classes.closeBtn}
              variant="contained"
            >Return to Dashboard
            </Button>
          </div>
        </AppWrapper>
      );
    }

    const appointment = find(appointments.data, { user_id: userId }) || {};
    const emrPatients = filter(emr.data, { user_id: userId });
    const signatureRequests = esign.signatureRequests.filter(r => r.user_id === userId);
    let unverifiedPatient = false;
    const firstUnverified = find(emrPatients, { status: 'UNVERIFIED' });

    // Short circuits so we don't show any more potential matches after
    // one is matched. Preventing multiple matches per patient.
    if (firstUnverified && !find(emrPatients, { status: 'VERIFIED' })) {
      unverifiedPatient = firstUnverified;
    }

    let painLevel = 'No Pain Recorded';
    const migraines = this.props.migraines[userId];
    const mostRecentMigraine = orderBy(migraines, 'end_time', 'desc')[0];
    const migrainePain = get(mostRecentMigraine, 'pain[0]', null);
    if (migrainePain) {
      const painType = capitalize(migrainePain.pain_type);
      painLevel = `${painType} Pain ● Level ${migrainePain.severity}`;
    }
    const relevantPainHx = chain(painHx.data)
      .map(p => p)
      .filter(p => p.user_id === userId)
      .orderBy('pain_start_date', 'desc')
      .value();
    const patientForms = forms[userId];
    const generalProps = {
      appointments,
      clinicId,
      contact_preference,
      router,
      forms: patientForms,
      appointment,
      handleOpenForm,
      handleOpenAllForms,
      mergeData,
      updateAppointment,
      painLevel,
      onOpenCheckInModal: this.handleOpenCheckInModal,
      proSubmissions,
      documentTemplates,
      onCreateAppointment: createAppointment, 
      unverifiedPatient,
      emrInstances,
      onChangeTab: this.handleChangeTab,
      userId,
      roles: appointment.roles || [],
      features: this.props.features,
    };

    const tabProps = {
      activeTab: tab,
      emrMergeAvailable: this.props.merge,
      onChangeTab: this.handleChangeTab,
      showEmrAlert: Boolean(unverifiedPatient),
      features: this.props.features,
      proSubmissions,
      scrollabel: true,
    };
    return (
      <AppWrapper iconSidebar={true} router={this.props.router}>
        <div className={this.props.classes.contentStyle}>
          <PatientViewHeader
            avatarImg={appointment.avatarImgLarge}
            centerContent={(
              <PatientSummary
                {...generalProps}
                {...appointment}
                classes={{}}
                blacklistedPhone={this.state.blacklistedPhone}
                landlinePhone={this.state.landlinePhone}
                verifiedPatientNumber={this.state.verifiedPatientNumber}
                contactStatus={this.state.contactStatus}
              />
            )}
            rightContent={(
              <PatientHeaderProgressCircles {...generalProps} />
            )}
          />
          {tab === 'PATIENT_OVERVIEW' && (
            <PatientOverviewTab
              key={`appt-overview-${userId}`}
              {...generalProps}
            >
              <Tabs {...tabProps} />
            </PatientOverviewTab>
          )}
          {tab === 'APPOINTMENTS' && (
            <AppointmentsTab
              key={`appt-overview-${userId}`}
              {...generalProps}
              updatePatient={this.props.updatePatient}
            >
              <Tabs {...tabProps} />
            </AppointmentsTab>
          )}
          {tab === 'EMR_INTEGRATION' && (
            <EmrOverviewTab
              {...this.props}
              classes={{}}
              onClearEmrMessage={this.props.clearEmrMessage}
              editUserEmr={this.props.editUserEmr}
              emrPatients={emrPatients}
              key={`emr-overview-${userId}`}
              emrMergeAvailable={this.props.merge}
              mergeData={this.props.mergeData}
              mergePatientData={this.props.mergePatientData}
              readUserEhr={this.props.readUserEhr}
              readInformedData={this.props.readInformedData}
              newMatch={this.props.merge}
              {...generalProps}
            >
              <Tabs {...tabProps} />
            </EmrOverviewTab>
          )}
          {tab === 'ESIGN_RELEASES' && (
            <ReleasesOverviewTab
              key={`releases-${userId}`}
              documentTemplates={this.props.documentTemplates}
              signatureRequests={signatureRequests}
              handleSendSignatureRequest={this.handleSendSignatureRequest}
              {...generalProps}
            >
              <Tabs {...tabProps} />
            </ReleasesOverviewTab>
          )}
          {tab === 'PRO_SUBMISSIONS' && (
            <ProSubmissionsTab
              key={`submissions-${userId}`}
              proSubmissions={proSubmissions}
              onCreateProSubmission={this.props.createProSubmission}
              onCreateCampaign={this.props.createProCampaign}
              deleteProCampaign={this.props.deleteProCampaign}
              editProCampaign={this.props.editProCampaign}
              browseProCampaigns={this.props.browseProCampaigns}
              proCampaigns={this.props.proCampaigns}
              proTypes={this.props.proTypes}
              onAddSurgeryHx={this.props.addSurgeryHx}
              onEditSurgeryHx={this.props.editSurgeryHx}
              surgeryData={this.props.surgeryData}
              surgeryTypes={this.props.surgeryTypes}
              surgeries={surgeries}
              handleBrowseProSubmissions={this.handleBrowseProSubmissions}
              blacklistedPhone={this.state.blacklistedPhone}
              landlinePhone={this.state.landlinePhone}
              contactStatus={this.state.contactStatus}
              {...generalProps}
            >
              <Tabs {...tabProps} />
            </ProSubmissionsTab>
          )}
          {tab === 'HEADACHE_OVERVIEW' && (
            <HeadacheOverviewTab
              migraines={migraines}
              mostRecentMigraine={mostRecentMigraine}
              {...generalProps}
            >
              <Tabs {...tabProps} />
            </HeadacheOverviewTab>
          )}
          {tab === 'PAIN_OVERVIEW' && (
            <BodyOverviewTab
              key={`body-overview-${userId}`}
              migraines={migraines}
              mostRecentMigraine={mostRecentMigraine}
              painLocations={this.props.painLocations}
              painHx={relevantPainHx}
              {...generalProps}
            >
              <Tabs {...tabProps} />
            </BodyOverviewTab>
          )}
          {tab === 'VITALS_OVERVIEW' && (
            <VitalsOverviewTab
              {...generalProps}
            >
              <Tabs {...tabProps} />
            </VitalsOverviewTab>
          )}
          {tab === 'IMAGES_OVERVIEW' && (
            <ImagesOverviewTab
              key={`images-overview-${userId}`}
              migraines={migraines}
              mostRecentMigraine={mostRecentMigraine}
              painLocations={this.props.painLocations}
              painHx={relevantPainHx}
              {...generalProps}
            >
              <Tabs {...tabProps} />
            </ImagesOverviewTab>
          )}
        </div>
      </AppWrapper>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const {
    appData,
    appointments,
    clinic,
    emr,
    esign,
    forms,
    mergeData,
    migraines,
    painHx,
    proSubmissions,
    surgeryHx,
  } = state;
  const userId = get(ownProps, 'location.state.userId');
  const contact_preference = get(ownProps, 'location.state.contact_preference');
  const emrInstances = clinic.data.emr_instances;
  const selectedProSubmissions = filter(proSubmissions.proSubmissions, { user_id: userId }) || [];
  const selectedProCampaigns = reduce(proSubmissions.proCampaigns, (allCampaigns, campaign) => {
    if(campaign.events[0].target_user_id === userId) {
      allCampaigns.push(campaign);
    }
    return allCampaigns;
  }, []);

  return {
    appointments,
    clinicId: clinic.clinicId,
    contact_preference,
    documentTemplates: esign.documents,
    emr,
    emrInstances,
    esign,
    forms,
    features: get(clinic, 'data.app_features', []),
    merge: mergeData.merge,
    mergeData,
    migraines,
    painHx,
    painLocations: appData.painLocations,
    proSubmissions: selectedProSubmissions,
    proTypes: map(appData.proTypes, p => p.pro_type).sort(),
    proCampaigns: selectedProCampaigns,
    surgeryTypes: appData.surgery || [],
    surgeryData: surgeryHx.data,
    userId,
  };
}

const actionCreators = {
  addSurgeryHx,
  addRecent,
  addSignatureRequest,
  browseDocuments,
  browseProCampaigns,
  browseProviders,
  browseSignatureRequests,
  browsePainLocations,
  browsePainHx,
  browseProTypes,
  browseProSubmissions,
  browseSurgery,
  browseSurgeryHx,
  clearEmrMessage,
  createAppointment,
  createProCampaign,
  createProSubmission,
  deleteProCampaign,
  editProSubmission,
  editProCampaign,
  editUser,
  editUserEmr,
  editSurgeryHx,
  fetchForms,
  fetchMigraines,
  handleSkip,
  mergePatientData,
  openModal,
  readPatient,
  readInformedData,
  readUserEhr,
  readUserEmr,
  unmatchPatient,
  updateAppointment,
  updatePatient,
};

AppointmentView.defaultProps = {
  appointments: {},
  documentTemplates: [],
  emrInstances: [],
  forms: {},
  migraines: {},
  painHx: {},
  painLocations: {},
};

AppointmentView.propTypes = {
  addSignatureRequest: PropTypes.func.isRequired,
  addSurgeryHx: PropTypes.func.isRequired,
  appointments: PropTypes.object,
  browseProCampaigns: PropTypes.func.isRequired,
  browsePainHx: PropTypes.func.isRequired,
  browseSignatureRequests: PropTypes.func.isRequired,
  browseSurgery: PropTypes.func.isRequired,
  browseSurgeryHx: PropTypes.func.isRequired,
  clinicId: PropTypes.number,
  createProCampaign: PropTypes.func.isRequired,
  deleteProCampaign: PropTypes.func.isRequired,
  documentTemplates: PropTypes.array,
  editProSubmission: PropTypes.func.isRequired,
  editProCampaign: PropTypes.func.isRequired,
  editUser: PropTypes.func,
  editUserEmr: PropTypes.func.isRequired,
  editSurgeryHx: PropTypes.func.isRequired,
  emr: PropTypes.object.isRequired,
  emrInstances: PropTypes.array.isRequired,
  esign: PropTypes.object,
  fetchForms: PropTypes.func.isRequired,
  fetchMigraines: PropTypes.func.isRequired,
  forms: PropTypes.object,
  handleSkip: PropTypes.func,
  merge: PropTypes.bool,
  mergeData: PropTypes.object.isRequired,
  mergePatientData: PropTypes.func,
  migraines: PropTypes.object,
  painLocations: PropTypes.object,
  painHx: PropTypes.object,
  readPatient: PropTypes.func.isRequired,
  readInformedData: PropTypes.func,
  readUserEhr: PropTypes.func,
  readUserEmr: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  updateAppointment: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(AppointmentView));
