import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { round, mapValues, remove, isNull } from 'lodash';

import Calendar from '../components/calendar';
import PatientViewContentSection from './patient-view-content-section';
import VitalsLineCharts from './vitals-line-charts';
import VitalsTable from './vitals-table';
import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';

const styles = {
  calendarDisabled: {
    opacity: 0.4,
    pointerEvent: 'none',
  },
  calendarWrapper: {
    flex: 1,
    margin: 10,
    maxWidth: 300,
    minWidth: 300,
  },
  circularProgressWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
  },
  vitalsLineChartsWrapper: {
    marginTop: 10,
    overflowX: 'scroll',
  },
  vitalsTableWrapper: {
    margin: 5,
  },
};

const monthlyChartWidth = 800;

const valuePrecisions = {
  BMI: 1,
  temperature: 1,
  HR: 0,
  BR: 0,
  SPO2: 0,
  BP: 0,
};

const roundValue = (value, type) => {
  return round(value, valuePrecisions[type]);
};

const getDateWithoutTime = (dateStr) => {
  const date = new Date(dateStr);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

const getDomainForAllMeasurements = vitals => {
  const datesSet = new Set();
  let domain = [];

  vitals.forEach(vital => {
    const date = getDateWithoutTime(vital.recorded_at);
    datesSet.add(date.toISOString());
  });

  datesSet.forEach(dateStr => {
    const dateObj = new Date(dateStr);
    domain.push(dateObj);
  })

  domain.sort((a, b) => a.getTime() - b.getTime());
  domain = domain.map(date => moment(date).format('YYYY-MM-DD'));

  return domain;
};

const getProcessedVitalsDataForMonth = vitals => {
  let processedData = {
    HR: [],
    BR: [],
    BMI: [],
    temperature: [],
    SPO2: [],
    systolic: [],
    diastolic: [],
  };
  const vitalsKeyedByDate = {};

  vitals.forEach(vital => {
    const recordedAt = moment(new Date(vital.recorded_at));
    const date = recordedAt.date();
    const dateString = recordedAt.format('YYYY-MM-DD');

    if (!vitalsKeyedByDate[date]) {
      vitalsKeyedByDate[date] = {};
    }
    const type = vital.type;
    if (type === 'BP') {
      vitalsKeyedByDate[date].diastolic = roundValue(vital.secondary_value, 'diastolic');
      vitalsKeyedByDate[date].systolic = roundValue(vital.value, 'systolic');
      vitalsKeyedByDate[date].dateString = dateString;
    } else {
      vitalsKeyedByDate[date][type] = roundValue(vital.value, type);
      vitalsKeyedByDate[date].dateString = dateString;
    }
  });

  Object.keys(vitalsKeyedByDate).forEach(date => {
    processedData.BR.push({
      date,
      value: vitalsKeyedByDate[date].BR || null,
      dateString: vitalsKeyedByDate[date].dateString,
    });
    processedData.HR.push({
      date,
      value: vitalsKeyedByDate[date].HR || null,
      dateString: vitalsKeyedByDate[date].dateString,
    });
    processedData.BMI.push({
      date,
      value: vitalsKeyedByDate[date].BMI || null,
      dateString: vitalsKeyedByDate[date].dateString,
    });
    processedData.temperature.push({
      date,
      value: vitalsKeyedByDate[date].temperature || null,
      dateString: vitalsKeyedByDate[date].dateString,
    });
    processedData.SPO2.push({
      date,
      value: vitalsKeyedByDate[date].SPO2 || null,
      dateString: vitalsKeyedByDate[date].dateString,
    });
    processedData.diastolic.push({
      date,
      value: vitalsKeyedByDate[date].diastolic || null,
      dateString: vitalsKeyedByDate[date].dateString,
    });
    processedData.systolic.push({
      date,
      value: vitalsKeyedByDate[date].systolic || null,
      dateString: vitalsKeyedByDate[date].dateString,
    });
  });
  processedData = mapValues(processedData, (section) => {
    return remove(section, date => !isNull(date.value));
  });
  return processedData;
};

const getProcessedVitalsDataForDay = vitals => {
  const vitalsGroupedByTime = {};
  let processedData = [];
  
  vitals.forEach(vital => {
    const time = moment(vital.recorded_at).format('h:mm a');
    
    if (!vitalsGroupedByTime[time]) {
      vitalsGroupedByTime[time] = {
        recordedAt: vital.recorded_at,
      };
    }
    const type = vital.type;
    if (type === 'BP') {
      vitalsGroupedByTime[time].diastolic = roundValue(vital.secondary_value, 'diastolic');
      vitalsGroupedByTime[time].systolic = roundValue(vital.value, 'systolic');
    } else {
      vitalsGroupedByTime[time][type] = roundValue(vital.value, type);
    }
  });

  Object.keys(vitalsGroupedByTime).forEach(time => {
    processedData.push({
      time,
      BR: vitalsGroupedByTime[time].BR || null,
      HR: vitalsGroupedByTime[time].HR || null,
      BMI: vitalsGroupedByTime[time].BMI || null,
      temperature: vitalsGroupedByTime[time].temperature || null,
      SPO2: vitalsGroupedByTime[time].SPO2 || null,
      systolic: vitalsGroupedByTime[time].systolic || null,
      diastolic: vitalsGroupedByTime[time].diastolic || null,
    });
  });

  processedData.sort((a, b) => a.recordedAt - b.recordedAt);
  return processedData;
};

const getProcessedVitalsDataForAllMeasurements = vitals => {
  let processedData = {
    HR: [],
    BR: [],
    BMI: [],
    temperature: [],
    SPO2: [],
    diastolic: [],
    systolic: [],
  };
  const vitalsKeyedByDate = {};

  vitals.forEach(vital => {
    if (vital.value < 1) {
      return;
    }
    const date = getDateWithoutTime(vital.recorded_at);
    if (!vitalsKeyedByDate[date]) {
      vitalsKeyedByDate[date] = {};
    };
    const type = vital.type;
    if (type === 'BP') {
      vitalsKeyedByDate[date].diastolic = roundValue(vital.secondary_value, 'diastolic');
      vitalsKeyedByDate[date].systolic = roundValue(vital.value, 'systolic');
    } else {
      vitalsKeyedByDate[date][type] = roundValue(vital.value, type);
    }
  });

  Object.keys(vitalsKeyedByDate).forEach(date => {
    const dateObj = moment(date).format('YYYY-MM-DD');
    processedData.BR.push({
      date: dateObj,
      value: vitalsKeyedByDate[date].BR || null,
    });
    processedData.HR.push({
      date: dateObj,
      value: vitalsKeyedByDate[date].HR || null,
    });
    processedData.BMI.push({
      date: dateObj,
      value: vitalsKeyedByDate[date].BMI || null,
    });
    processedData.temperature.push({
      date: dateObj,
      value: vitalsKeyedByDate[date].temperature || null,
    });
    processedData.SPO2.push({
      date: dateObj,
      value: vitalsKeyedByDate[date].SPO2 || null,
    });
    processedData.diastolic.push({
      date: dateObj,
      value: vitalsKeyedByDate[date].diastolic || null,
    });
    processedData.systolic.push({
      date: dateObj,
      value: vitalsKeyedByDate[date].systolic || null,
    });
  });
  processedData = mapValues(processedData, (section) => {
    return remove(section, date => !isNull(date.value));
  });
  return processedData;
}

class VitalsOverviewTab extends React.Component {
  constructor(props) {
    super(props);

    const currDate = new Date();
    const calendarMonth = currDate.getMonth();
    const calendarYear = currDate.getFullYear();

    this.state = {
      vitalsData: null,
      vitalsDataTimePeriod: 'month', // 'month' or 'day'
      vitalsDataRaw: null,
      fetchingVitalsData: true,
      currentMonth: null,
      currentYear: null,
      firstDateOfCurMonth: null,
      lastDateOfCurMonth: null,
      selectedDate: null,
      daysWithVitals: new Set(),
      calendarMonth,
      calendarYear,
    };

    apiFetch(`/users/${props.userId}/dates_with_vitals`)
      .then((data) => {
        const daysWithVitals = new Set();
        data.forEach(dateStr => {
          const dateObj = moment(dateStr);
          daysWithVitals.add(dateObj.format('YYYY-MM-DD'));
        });
        this.setState({ daysWithVitals });
      });
      apiFetch(`/users/${props.userId}/medication_hx`, { method: 'GET' })
      .then((data) => {
        this.setState({ medications: data });
      }).catch(() => this.setState({ medications: false }));
  }

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  handleClickCalendarBack = () => {
    let { calendarMonth, calendarYear } = this.state;

    calendarMonth = (calendarMonth === 0) ? 11 : (calendarMonth - 1);
    calendarYear = (calendarMonth === 11) ? (calendarYear - 1) : calendarYear;

    this.handleDateChange(calendarMonth, calendarYear);
    this.setState({ calendarMonth, calendarYear });
  }

  handleClickCalendarForward = () => {
    let { calendarMonth, calendarYear } = this.state;

    calendarMonth = (calendarMonth + 1) % 12;
    calendarYear = (calendarMonth === 0) ? (calendarYear + 1) : calendarYear;

    this.handleDateChange(calendarMonth, calendarYear);
    this.setState({ calendarMonth, calendarYear });
  }

  componentDidMount() {
    const curDate = new Date();
    const startTime = new Date(curDate.getFullYear(), curDate.getMonth(), 1);
    const endTime = new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0);

    this.setState({
      firstDateOfCurMonth: startTime.getDate(),
      lastDateOfCurMonth: endTime.getDate(),
    });
  }

  componentWillUnmount() {
    // TODO WIP - find a better way to do this...cannot find whatever leak is triggering this
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback)=>{
      return;
    };  
  }

  getVitalsData = (startTime, endTime, timePeriod) => {
    const { userId } = this.props;

    apiFetch(`/vitals`, {
      query: {
        user_id: userId,
        start_time: startTime,
        end_time: endTime,
        for_day: timePeriod === 'day',
      },
    })
      .then((response) => {
        let processedData;
        let all_vitals_measurements = response.all_vitals_measurements;
        if (all_vitals_measurements === true) {
          processedData = getProcessedVitalsDataForAllMeasurements(response.vitals_data);
        } else if (timePeriod === 'month') {
          processedData = getProcessedVitalsDataForMonth(response.vitals_data);
        } else {
          processedData = getProcessedVitalsDataForDay(response.vitals_data);
        }

        let vitalsDataTimePeriod = all_vitals_measurements === true
          ? 'all'
          : timePeriod;

        this.setState({
          vitalsData: processedData,
          fetchingVitalsData: false,
          vitalsDataRaw: response.vitals_data,
          vitalsDataTimePeriod,
        });
      })
      .catch(() => {
        this.setState({ fetchingVitalsData: false });
      });
  }

  // update function to set month, year
  handleDayChange = (selectedDate) => {
    const startTime = new Date(`${selectedDate}T00:00:00`); // if time is not included, date will default to midnight utc
    const endTime = new Date(startTime.getTime() + (24 * 60 * 60 * 1000) - 1);
    const calendarMonth = startTime.getMonth();
    const calendarYear = startTime.getFullYear();

    startTime.setMinutes(startTime.getTimezoneOffset());
    endTime.setMinutes(endTime.getTimezoneOffset());
    
    const startTimeStr = startTime.toISOString();
    const endTimeStr = endTime.toISOString();

    this.getVitalsData(startTimeStr, endTimeStr, 'day');

    this.setState({
      selectedDate,
      calendarMonth,
      calendarYear,
      vitalsData: null,
      vitalsDataTimePeriod: 'day',
    });
  };

  handleDateChange = (month, year) => {
    const { vitalsDataTimePeriod } = this.state;

    if (vitalsDataTimePeriod === 'all') return;
    
    const startTime = new Date(year, month, 1);
    const endTime = new Date(year, month + 1, 1);
    endTime.setTime(endTime.getTime() - 1);
    const startTimeStr = startTime.toISOString();
    const endTimeStr = endTime.toISOString();

    this.setState({
      fetchingVitalsData: true,
      firstDateOfCurMonth: startTime.getDate(),
      lastDateOfCurMonth: endTime.getDate(),
      selectedDate: null,
      vitalsData: null,
      vitalsDataTimePeriod: 'month',
      vitalsDataRaw: null,
    });

    this.getVitalsData(startTimeStr, endTimeStr, 'month');
  }

  handleClickCurrentMonth = (month, year) => {
    this.handleDateChange(month, year);
  }

  render() {
    const {
      firstDateOfCurMonth,
      lastDateOfCurMonth,
      fetchingVitalsData,
      vitalsData,
      selectedDate,
      vitalsDataTimePeriod,
      vitalsDataRaw,
      daysWithVitals,
      calendarMonth,
      calendarYear,
      medications
    } = this.state;

    const calendarWrappperStyles = fetchingVitalsData
      ? { ...styles.calendarWrapper, ...styles.calendarDisabled }
      : styles.calendarWrapper;

    let axisTopDomain;
    let chartWidth;
    let allHistoricalMeasurements;
    if (vitalsData) {
      if (vitalsDataTimePeriod === 'all') {
        axisTopDomain = getDomainForAllMeasurements(vitalsDataRaw);
        chartWidth = axisTopDomain.length * 60;
        allHistoricalMeasurements = true;
      } else if (vitalsDataTimePeriod === 'month') {
        axisTopDomain = [firstDateOfCurMonth, lastDateOfCurMonth];
        chartWidth = monthlyChartWidth;
        allHistoricalMeasurements = false;
      }
    }
    return (
      <>
        {this.props.children}
        <PatientViewContentSection>
          <div style={styles.contentContainer}>
            <div style={calendarWrappperStyles}>
              <Calendar
                clickableDates={true}
                data={[]}
                onDateChange={this.handleDateChange}
                onClickDay={this.handleDayChange}
                onClickCurrentMonth={this.handleClickCurrentMonth}
                selectedDate={selectedDate}
                selectedDateColor={colors.primaryColor}
                daysWithVitals={daysWithVitals}
                onClickBack={this.handleClickCalendarBack}
                onClickForward={this.handleClickCalendarForward}
                month={calendarMonth}
                year={calendarYear}
              />
              <div style={{textAlign: 'start', paddingLeft: '30px'}}>
                <h4>Medications:</h4>
                {medications ? medications.map((med, idx) => {
                  return (
                    <div style={{paddingBottom: '5px'}} key={idx}>
                    <p style={{fontSize: '14px'}}>{this.capitalize(med.name)}</p>
                    <p style={{fontSize: '14px'}}>Dosage: {med.dosage}</p>
                    <p style={{fontSize: '14px'}}>Start Date: {moment(med.start_date).format('L')}</p>
                  </div>
                  )
                }) : (
                  <p>No medications</p>
                )}
              </div>
            </div>
            {fetchingVitalsData ? (
              <div style={styles.circularProgressWrapper}>
                <CircularProgress />
              </div>
            ) : null}
            {vitalsData && (vitalsDataTimePeriod === 'month' || vitalsDataTimePeriod === 'all') ? (
              <div style={styles.vitalsLineChartsWrapper}>
                <VitalsLineCharts
                  axisTopDomain={axisTopDomain}
                  axisTopRange={[0, chartWidth]}
                  chartWidth={chartWidth}
                  vitalsData={vitalsData}
                  allHistoricalMeasurements={allHistoricalMeasurements}
                  onClickDataPoint={this.handleDayChange}
                />
              </div>
            ) : null}
            {vitalsData && vitalsDataTimePeriod === 'day' ? (
              <div style={styles.vitalsTableWrapper}>
                <VitalsTable
                  vitalsData={vitalsData}
                />
              </div>
            ) : null }
          </div>
        </PatientViewContentSection>
      </>
    );
  }
}

VitalsOverviewTab.propTypes = {
  children: PropTypes.element.isRequired,
};

export default VitalsOverviewTab;
